import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  getUserInvitationFormData() {
    let url = urlList.urlRoot + "user/invitations/form-data/";
    return apiClient.get(url);
  },

  createUserInvitation(formData) {
    let url = urlList.urlRoot + "user/invitations/";
    return apiClient.post(url, formData);
  },

  retrieveUserInvitation(userInvitationUUID) {
    let url =
      urlList.urlRoot +
      `user/invitations/${userInvitationUUID}/invitation-data/`;
    return apiClient.get(url);
  },

  acceptInvitation(userInvitationUUID, formData) {
    let url =
      urlList.urlRoot +
      `user/invitations/${userInvitationUUID}/accept-invitation/`;
    return apiClient.post(url, formData);
  },

  rejectInvitation(userInvitationUUID, formData) {
    let url =
      urlList.urlRoot +
      `user/invitations/${userInvitationUUID}/reject-invitation/`;
    return apiClient.post(url, formData);
  },

  getUserInvitationsList(
    perPage,
    page,
    searchQuery,
    permissionRoles,
    isExpired,
    isSignedUp
  ) {
    let url =
      urlList.urlRoot +
      "user/invitations/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (permissionRoles) {
      url += "&permission_roles=" + permissionRoles;
    }

    if (isExpired == true) {
      url += "&is_expired=" + true;
    }

    if (isSignedUp == true) {
      url += "&is_signed_up=" + true;
    }

    return apiClient.get(url);
  },

  deleteUserInvitation(userInvitationUUID) {
    let url = urlList.urlRoot + `user/invitations/${userInvitationUUID}/`;
    return apiClient.delete(url);
  },

  resendUserInvitation(userInvitationUUID) {
    let url =
      urlList.urlRoot +
      `user/invitations/${userInvitationUUID}/resend-invitation/`;
    return apiClient.post(url);
  },
};
