import ServiceInvitations from "../../service/invitations/ServiceInvitations.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  userInivtationList: [],
  userInvitationCount: null,
};

export const mutations = {
  SET_USER_INVITATIONS_LIST(state, data) {
    state.userInivtationList = data;
  },

  SET_USER_INIVTATIONS_COUNT(state, count) {
    state.userInvitationCount = count;
  },

  DELETE_USER_INVITATION(state, userInvitationUUID) {
    state.userInivtationList = state.userInivtationList.filter((element) => {
      if (element.invitation_uuid != userInvitationUUID) {
        return element;
      }
    });
  },

  ADD_USER_INVITATION(state, userInvitationObj) {
    state.userInivtationList.unshift(userInvitationObj);
  },

  UPDATE_USER_INVITATION(state, userInvitationObj) {
    state.userInivtationList = state.userInivtationList.map((element) => {
      if (element.id === userInvitationObj.id) {
        element = JSON.parse(JSON.stringify(userInvitationObj));
      }
      return element;
    });
  },
};

export const actions = {
  getUserInvitationFormData({}) {
    return ServiceInvitations.getUserInvitationFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getUserInvitationsList(
    { commit },
    { perPage, page, searchQuery, permissionRoles, isExpired, isSignedUp }
  ) {
    return ServiceInvitations.getUserInvitationsList(
      perPage,
      page,
      searchQuery,
      permissionRoles,
      isExpired,
      isSignedUp
    )
      .then((resp) => {
        commit("SET_USER_INVITATIONS_LIST", resp.data.results);
        commit("SET_USER_INIVTATIONS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  createUserInvitation({ commit }, formData) {
    return ServiceInvitations.createUserInvitation(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "User invited successfully",
        });
        commit("ADD_USER_INVITATION", resp.data);
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.permission_role) {
            message = "Role:" + err.response.data.permission_role;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          } else if (err.response.data.message) {
            message = err.response.data.message[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }
        return err.response;
      });
  },

  retrieveUserInvitation({}, userInvitationUUID) {
    return ServiceInvitations.retrieveUserInvitation(userInvitationUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  deleteUserInvitation({ commit }, userInvitationUUID) {
    return ServiceInvitations.deleteUserInvitation(userInvitationUUID)
      .then((resp) => {
        commit("DELETE_USER_INVITATION", userInvitationUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "User invitation deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  acceptInvitation({}, { userInvitationUUID, formData }) {
    return ServiceInvitations.acceptInvitation(userInvitationUUID, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invitation accepted successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  rejectInvitation({}, { userInvitationUUID, formData }) {
    return ServiceInvitations.rejectInvitation(userInvitationUUID, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invitation declined successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  resendUserInvitation({ commit }, userInvitationUUID) {
    return ServiceInvitations.resendUserInvitation(userInvitationUUID)
      .then((resp) => {
        commit("UPDATE_USER_INVITATION", resp.data);

        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Invitation resent successfully",
        });

        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },
};
