import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
  urlPasswordReset: "/api/v1/password_reset/",
};

export default {
  updateUser(userUUID, formData) {
    let url = urlList.urlRoot + `users/${userUUID}/`;
    return apiClient.patch(url, formData);
  },

  confirmCurrentUserInfoChange(formData) {
    let url = urlList.urlRoot + `users/current/info/confirm-change/`;
    return apiClient.post(url, formData);
  },

  verifyOtpToConfirmCurrentUserInfoChange(formData) {
    let url = urlList.urlRoot + `users/current/info/confirm-change/verify-otp/`;
    return apiClient.post(url, formData);
  },

  changeUserPassword(formData) {
    let url = urlList.urlRoot + "users/change-password/";
    return apiClient.post(url, formData);
  },

  signupUser(formData) {
    let url = urlList.urlRoot + "signup/";
    return apiClient.post(url, formData);
  },

  loginUser(formData) {
    let url = urlList.urlRoot + "login/";
    return apiClient.post(url, formData);
  },

  verifyOtpTologinUser(formData) {
    let url = urlList.urlRoot + "login/verify-otp/";
    return apiClient.post(url, formData);
  },

  resendOtpTologinUser(formData) {
    let url = urlList.urlRoot + "login/resend-otp/";
    return apiClient.post(url, formData);
  },

  // password reset
  resetPassword(data) {
    return apiClient.post(urlList.urlPasswordReset, data);
  },

  resetPasswordConfirm(data) {
    let url = urlList.urlPasswordReset + "confirm/";
    return apiClient.post(url, data);
  },

  resetPasswordValidateToken(data) {
    let url = urlList.urlPasswordReset + "validate_token/";
    return apiClient.post(url, data);
  },
};
