import ServiceReports from "../../service/reports/ServiceReports.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  vehiclesWofReportList: [],
  vehiclesServiceReminderReportList: [],
  vehiclesWofReportCount: null,
  vehiclesServiceReminderReportCount: null,
  vehiclesWofReportPdfTaskId: null,
  vehiclesServiceReminderReportPdfTaskId: null,
  vehiclesWofReportExcelTaskId: null,
  vehiclesServiceReminderReportExcelTaskId: null,

  vehiclesWofConlictOfInterestReportList: [],
  vehiclesWofConlictOfInterestReportCount: null,
  vehiclesWofConflictOfInterestReportPdfTaskId: null,
  vehiclesWofConflictOfInterestReportExcelTaskId: null,

  duplicateCustomersList: [],
  duplicateCustomersCount: null,
};

export const mutations = {
  // vehicles reporting
  SET_VEHICLES_WOF_REPORT_LIST(state, data) {
    state.vehiclesWofReportList = data;
  },
  SET_VEHICLES_SERVICE_REMINDER_REPORT_LIST(state, data) {
    state.vehiclesServiceReminderReportList = data;
  },
  SET_VEHICLES_WOF_REPORT_COUNT(state, count) {
    state.vehiclesWofReportCount = count;
  },
  SET_VEHICLES_SERVICE_REMINDER_REPORT_COUNT(state, count) {
    state.vehiclesServiceReminderReportCount = count;
  },

  SET_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_LIST(state, data) {
    state.vehiclesWofConlictOfInterestReportList = data;
  },

  SET_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_COUNT(state, count) {
    state.vehiclesWofConlictOfInterestReportCount = count;
  },

  SAVE_VEHICLES_WOF_REPORT_PDF_TASK_ID(state, taskId) {
    state.vehiclesWofReportPdfTaskId = taskId;
  },

  SAVE_VEHICLES_SERVICE_REMINDER_REPORT_PDF_TASK_ID(state, taskId) {
    state.vehiclesServiceReminderReportPdfTaskId = taskId;
  },

  SAVE_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_PDF_TASK_ID(state, taskId) {
    state.vehiclesWofConflictOfInterestReportPdfTaskId = taskId;
  },

  CLEAR_VEHICLES_WOF_REPORT_PDF_TASK_ID(state) {
    state.vehiclesWofReportPdfTaskId = null;
  },

  CLEAR_VEHICLES_SERVICE_REMINDER_REPORT_PDF_TASK_ID(state) {
    state.vehiclesServiceReminderReportPdfTaskId = null;
  },

  CLEAR_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_PDF_TASK_ID(state) {
    state.vehiclesWofConflictOfInterestReportPdfTaskId = null;
  },

  SAVE_VEHICLES_WOF_REPORT_EXCEL_TASK_ID(state, taskId) {
    state.vehiclesWofReportExcelTaskId = taskId;
  },

  SAVE_VEHICLES_SERVICE_REMINDER_REPORT_EXCEL_TASK_ID(state, taskId) {
    state.vehiclesServiceReminderReportExcelTaskId = taskId;
  },

  SAVE_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_EXCEL_TASK_ID(state, taskId) {
    state.vehiclesWofConflictOfInterestReportExcelTaskId = taskId;
  },

  CLEAR_VEHICLES_WOF_REPORT_EXCEL_TASK_ID(state) {
    state.vehiclesWofReportExcelTaskId = null;
  },

  CLEAR_VEHICLES_SERVICE_REMINDER_REPORT_EXCEL_TASK_ID(state) {
    state.vehiclesServiceReminderReportExcelTaskId = null;
  },

  CLEAR_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_EXCEL_TASK_ID(state) {
    state.vehiclesWofConflictOfInterestReportExcelTaskId = null;
  },

  // customer
  SET_DUPLICATE_CUSTOMERS_LIST(state, data) {
    state.duplicateCustomersList = Object.keys(data).map(
      (duplicatePhoneNo) => ({
        duplicatePhoneNo,
        values: data[duplicatePhoneNo],
      })
    );
  },

  SET_DUPLICATE_CUSTOMERS_COUNT(state, count) {
    state.duplicateCustomersCount = count;
  },
};

export const actions = {
  // vehicle wof report
  getVehiclesWofReport(
    { commit },
    {
      perPage,
      page,
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders,
    }
  ) {
    return ServiceReports.getVehiclesWofReport(
      perPage,
      page,
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders
    )
      .then((resp) => {
        commit("SET_VEHICLES_WOF_REPORT_LIST", resp.data.results);
        commit("SET_VEHICLES_WOF_REPORT_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesWofReportPDF(
    { commit },
    {
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders,
    }
  ) {
    return ServiceReports.generateVehiclesWofReportPDF(
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders
    )
      .then((resp) => {
        commit("SAVE_VEHICLES_WOF_REPORT_PDF_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating report pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesWofReportPDFTaskStatus({ commit }, formData) {
    return ServiceReports.checkVehiclesWofReportPDFTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_VEHICLES_WOF_REPORT_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesWofReportExcel(
    { commit },
    {
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders,
    }
  ) {
    return ServiceReports.generateVehiclesWofReportExcel(
      searchQuery,
      wofExpiryDateFrom,
      wofExpiryDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedWofReminders
    )
      .then((resp) => {
        commit("SAVE_VEHICLES_WOF_REPORT_EXCEL_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating excel report. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesWofReportExcelTaskStatus({ commit }, formData) {
    return ServiceReports.checkVehiclesWofReportExcelTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_VEHICLES_WOF_REPORT_EXCEL_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  notifyVehicleOwnerWofExpiration({}, { vehicleUUID, formData }) {
    return ServiceReports.notifyVehicleOwnerWofExpiration(vehicleUUID, formData)
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending WOF expiration notice to owner!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  notifyVehicleOwnerWofExpirationInBulk({}, formData) {
    return ServiceReports.notifyVehicleOwnerWofExpirationInBulk(formData)
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending WOF expiration notice to the owners!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  suppressWofReminder({}, formData) {
    return ServiceReports.suppressWofReminder(formData)
      .then((resp) => {
        let message = "";
        if (resp.data.suppress == true) {
          message = "Vehicle's WOF reminder suppressed!";
        } else {
          message = "Undo vehicle's WOF reminder suppress!";
        }
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  suppressWofReminderBulk({}, formData) {
    return ServiceReports.suppressWofReminderBulk(formData)
      .then((resp) => {
        let message = "";
        if (resp.data.suppress == true) {
          message = "Vehicles' WOF reminder suppressed!";
        } else {
          message = "Undo vehicles' WOF reminder suppress!";
        }
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  // vehicle service reminder report
  getVehiclesServiceReminderReport(
    { commit },
    {
      perPage,
      page,
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedServiceReminders,
    }
  ) {
    return ServiceReports.getVehiclesServiceReminderReport(
      perPage,
      page,
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
      suppressedServiceReminders
    )
      .then((resp) => {
        commit("SET_VEHICLES_SERVICE_REMINDER_REPORT_LIST", resp.data.results);
        commit("SET_VEHICLES_SERVICE_REMINDER_REPORT_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesServiceReminderReportPDF(
    { commit },
    {
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
    }
  ) {
    return ServiceReports.generateVehiclesServiceReminderReportPDF(
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob
    )
      .then((resp) => {
        commit(
          "SAVE_VEHICLES_SERVICE_REMINDER_REPORT_PDF_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating report pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesServiceReminderReportPDFTaskStatus({ commit }, formData) {
    return ServiceReports.checkVehiclesServiceReminderReportPDFTaskStatus(
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_VEHICLES_SERVICE_REMINDER_REPORT_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesServiceReminderReportExcel(
    { commit },
    {
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob,
    }
  ) {
    return ServiceReports.generateVehiclesServiceReminderReportExcel(
      searchQuery,
      serviceDueDateFrom,
      serviceDueDateTo,
      jobWithinAYear,
      historicalReminder,
      bookedOnJob
    )
      .then((resp) => {
        commit(
          "SAVE_VEHICLES_SERVICE_REMINDER_REPORT_EXCEL_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating excel report. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesServiceReminderReportExcelTaskStatus({ commit }, formData) {
    return ServiceReports.checkVehiclesServiceReminderReportExcelTaskStatus(
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_VEHICLES_SERVICE_REMINDER_REPORT_EXCEL_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  notifyVehicleOwnerServiceReminderExpiration({}, { vehicleUUID, formData }) {
    return ServiceReports.notifyVehicleOwnerServiceReminderExpiration(
      vehicleUUID,
      formData
    )
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending vehicle service due notice to owner!",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  notifyVehicleOwnerServiceReminderExpirationInBulk({}, formData) {
    return ServiceReports.notifyVehicleOwnerServiceReminderExpirationInBulk(
      formData
    )
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending vehicles' service due notices to the owners!",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  suppressServiceReminder({}, formData) {
    return ServiceReports.suppressServiceReminder(formData)
      .then((resp) => {
        let message = "";
        if (resp.data.suppress == true) {
          message = "Vehicle's Service reminder suppressed!";
        } else {
          message = "Undo vehicle's Service reminder suppress!";
        }
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  suppressServiceReminderBulk({}, formData) {
    return ServiceReports.suppressServiceReminderBulk(formData)
      .then((resp) => {
        let message = "";
        if (resp.data.suppress == true) {
          message = "Vehicles' Service reminder suppressed!";
        } else {
          message = "Undo vehicles' Service reminder suppress!";
        }
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: message,
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });

        console.log(err);
        return err.response;
      });
  },

  // vehicle wof conflict of interest report
  getVehiclesWofConflictOfInterestReport(
    { commit },
    { perPage, page, searchQuery, wofJobDateFrom, wofJobDateTo }
  ) {
    return ServiceReports.getVehiclesWofConflictOfInterestReport(
      perPage,
      page,
      searchQuery,
      wofJobDateFrom,
      wofJobDateTo
    )
      .then((resp) => {
        commit(
          "SET_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_LIST",
          resp.data.results
        );
        commit(
          "SET_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_COUNT",
          resp.data.count
        );
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesWofConflictOfInterestReportPDF(
    { commit },
    { searchQuery, wofJobDateFrom, wofJobDateTo }
  ) {
    return ServiceReports.generateVehiclesWofConflictOfInterestReportPDF(
      searchQuery,
      wofJobDateFrom,
      wofJobDateTo
    )
      .then((resp) => {
        commit(
          "SAVE_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_PDF_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating report pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesWofConflictOfInterestReportPDFTaskStatus({ commit }, formData) {
    return ServiceReports.checkVehiclesWofConflictOfInterestReportPDFTaskStatus(
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_PDF_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateVehiclesWofConflictOfInterestReportExcel(
    { commit },
    { searchQuery, wofJobDateFrom, wofJobDateto }
  ) {
    return ServiceReports.generateVehiclesWofConflictOfInterestReportExcel(
      searchQuery,
      wofJobDateFrom,
      wofJobDateto
    )
      .then((resp) => {
        commit(
          "SAVE_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_EXCEL_TASK_ID",
          resp.data.task_id
        );
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating excel report. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesWofConflictOfInterestReportExcelTaskStatus(
    { commit },
    formData
  ) {
    return ServiceReports.checkVehiclesWofConflictOfInterestReportExcelTaskStatus(
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit(
            "CLEAR_VEHICLES_WOF_CONFLICT_OF_INTEREST_REPORT_EXCEL_TASK_ID"
          );
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // customer report
  getDuplicatePhoneCustomersList({ commit }, { perPage, page, searchQuery }) {
    return ServiceReports.getDuplicatePhoneCustomersList(
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_DUPLICATE_CUSTOMERS_LIST", resp.data.results);
        commit("SET_DUPLICATE_CUSTOMERS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
